import React, {useEffect, useState} from "react";
import ProjectSelect from "../ProjectSelect/ProjectSelect";


const Header = (props) => {
    const [isProd, setIsProd] = useState(false);

    useEffect(() => {
        if (window.location.origin.includes('staging') || window.location.origin.includes('test')
            || window.location.origin.includes('localhost')) {
            setIsProd(false);
        } else {
            setIsProd(true);
        }
    }, []);

    return (
        <div className="header">
            <img alt="Main Logo" src="/logo.png" width={100} className="p-1.5"/>
            <h1 className="text-xl ml-auto text-center text-white font-normal mb-0">Testing tool
                {isProd &&
                    <span className="pl-2 text-yellow-500"> - PROD</span>
                }</h1>
            <ProjectSelect/>
        </div>
    )
};

export default Header;